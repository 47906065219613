import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useProductStore } from './product';
import { useProductQuantitiesStore } from './product-quantities';
import { useProductOptionsStore } from './product-options';

type CartError = {
  lineNumber: number;
  message: string;
};

export const useCartStore = defineStore('cart', () => {
  const _adding = ref(false);
  const _cart = ref<ShopifyCart | null>(null);
  const _errors = ref<CartError[]>([]);

  const loadingCart = ref(false);
  const miniCartOpen = ref(false);
  const cart = computed(() => _cart.value);
  const adding = computed(() => _adding.value);
  const errors = computed(() => _errors.value);

  const clearErrors = () => {
    _errors.value = [];
  };

  const startAnimationTimeout = () => {
    // TODO implement power modal

    setTimeout(() => (_adding.value = false), 1000);
  };

  const getCart = async () => {
    loadingCart.value = true;

    try {
      const response = await cartAPI.get(LOCALIZED_PATH.CART);
      _cart.value = await response.json();
    } catch (error) {
      console.error(`Failed`, error);
    } finally {
      loadingCart.value = false;
    }
  };

  const addIndependentProductsToCart = async (items: ShopifyCartItem[]) => {
    const payload = { items };
    await addToCart(payload);
  };

  const addMainProductToCart = async () => {
    const productStore = useProductStore();
    const quantityStore = useProductQuantitiesStore();
    const optionsStore = useProductOptionsStore();

    const id: number | null =
      optionsStore.activeOption?.id || productStore.variantId;

    if (!id) return;

    const payload: ShopifyCartAddPayload = {
      items: [
        {
          id,
          quantity: quantityStore.quantity
        }
      ]
    };

    // if product is free, and can be added to cart, it's a point product
    if (optionsStore.activeOption?.price === 0) {
      if (window.Hobbii?.cartMessages) {
        payload.items[0].properties = {
          [window.Hobbii.cartMessages.pointProduct]:
            window.Hobbii?.cartMessages.paidWithPoints
        };
      }
    }

    // check if product is already in the cart, if product is a pattern
    if (productStore.isPattern) {
      const addedToCartAlready =
        _cart.value?.items.find((item) => item.id == productStore.variantId)
          ?.quantity || 0;
      if (addedToCartAlready > 0) {
        throw new Error(window.Hobbii?.errorMessages.errorAlreadyAdded);
      }
    }

    _adding.value = true;

    addToCart(payload, () => (_adding.value = false));
  };

  const addToCart = async (
    payload: ShopifyCartAddPayload,
    callback = () => {}
  ) => {
    // send payload as post request
    const response = await cartAPI.post(LOCALIZED_PATH.ADD, payload);

    if (response.ok) {
      startAnimationTimeout();
      await getCart();
      return;
    }

    const error = await extractErrorFromResponse(
      response as ShopifyCartErrorResponse
    );

    // Shopify response with stock-related errors
    if (response.status === HTTP_RESPONSE_TYPE.SHOPIFY_STOCK_ERROR) {
      // let animation continue, even with an error,
      // some items could be added to the cart.
      startAnimationTimeout();
      await getCart();

      throw error;
    }

    callback();
    throw error;
  };

  const cartChangeQty = async (lineNumber: number, quantity: number) => {
    loadingCart.value = true;

    const payload = { line: lineNumber, quantity };

    const response = await cartAPI.post(LOCALIZED_PATH.CHANGE, payload);

    if (response.ok) {
      _cart.value = await response.json();
    } else {
      const error = await extractErrorFromResponse(
        response as ShopifyCartErrorResponse
      );

      // As cartChangeQty is called directly in on-click events, we store the error(s) in the store.
      // Changes to store.errors are watched & handled in frontend/entrypoints/cart.js
      _errors.value.push({ lineNumber, message: error.message });

      await getCart();
    }
  };

  return {
    loadingCart, // convert to computed when possible
    cart,
    adding,
    miniCartOpen,
    errors,
    getCart,
    addToCart,
    addMainProductToCart,
    addIndependentProductsToCart,
    cartChangeQty,
    clearErrors
  };
});

const cartAPI = {
  get: (url: string) =>
    fetch(url, {
      cache: 'no-store'
    }),
  post: (
    url: string,
    payload: ShopifyCartAddPayload | ShopifyCartChangePayload
  ) =>
    fetch(url, {
      cache: 'no-store',
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
};

const HTTP_RESPONSE_TYPE = {
  SHOPIFY_STOCK_ERROR: 422,
  TOO_MANY_REQUESTS: 429,
  SERVICE_UNAVAILABLE: 503
};

const LOCALIZED_PATH = {
  CART: window.Shopify.routes.root + 'cart.js',
  ADD: window.Shopify.routes.root + 'cart/add.js',
  CHANGE: window.Shopify.routes.root + 'cart/change.js'
};

const extractErrorFromResponse = async (response: ShopifyCartErrorResponse) => {
  // Shopify response with stock-related errors
  if (response.status === HTTP_RESPONSE_TYPE.SHOPIFY_STOCK_ERROR) {
    const error = await response.json();
    return new Error(error.description ?? error.message);
  }

  if (response.status === HTTP_RESPONSE_TYPE.TOO_MANY_REQUESTS) {
    return new Error(window.Hobbii?.errorMessages.tooManyRequests);
  }

  if (response.status === HTTP_RESPONSE_TYPE.SERVICE_UNAVAILABLE) {
    return new Error(window.Hobbii?.errorMessages.serviceUnavailable);
  }

  return new Error(window.Hobbii?.errorMessages.unknownErrorOccurred);
};
